<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">工作证明</div>
      <div class="back" @click="goback">返回上一页</div>
    </div>
    <div class="card-box">
      <div class="screen-box">
        <div class="screen-input-box">
          <div class="title-box">人员编码:</div>
          <el-input v-model="userCode" placeholder="人员编码" />
        </div>
        <div class="screen-input-box">
          <div class="title-box">人员名称:</div>
          <el-input v-model="userName" placeholder="人员名称" />
        </div>
        <div class="screen-input-box">
          <div class="title-box">护照号:</div>
          <el-input v-model="passportCode" placeholder="护照号" />
        </div>
        <div class="screen-input-box">
          <div class="title-box">国籍:</div>
          <el-select
            v-model="nationality"
            class="card-select"
            placeholder="国籍"
            filterable
            clearable
          >
            <el-option
              v-for="item in Countries"
              :key="item.ID"
              :label="item.CountryNameCHS"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">项目名称:</div>
          <el-select
            v-model="projectID"
            class="card-select"
            placeholder="项目名称"
            @change="changeProjectID"
            filterable
            clearable
          >
            <el-option
              v-for="item in projectList"
              :key="item.ProjectID"
              :label="item.ProjectName"
              :value="item.ProjectID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">分项目名称:</div>
          <el-select
            v-model="subProjectID"
            class="card-select"
            placeholder="分项目名称"
            filterable
            clearable
          >
            <el-option
              v-for="item in subProjectList"
              :key="item.ProjectID"
              :label="item.ProjectName"
              :value="item.ProjectID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">用工单位:</div>
          <el-select
            v-model="companyID"
            class="card-select"
            placeholder="用工单位"
            filterable
            clearable
          >
            <el-option
              v-for="item in companyList"
              :key="item.ID"
              :label="item.CompanyCHS"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">签证类型:</div>
          <el-select
            v-model="visaType"
            class="card-select"
            placeholder="签证类型"
            filterable
            clearable
          >
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <div class="screen-input-box">
          <div class="title-box">变更状态:</div>
          <el-select
            v-model="rescheduleStatus"
            class="card-select"
            placeholder="变更状态"
            filterable
            clearable
          >
            <el-option
              v-for="item in options3"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">审批状态:</div>
          <el-select
            v-model="auditStatus"
            class="card-select"
            placeholder="请选择审批状态"
            filterable
            clearable
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">外事关系:</div>
          <el-select
            v-model="foreignAffair"
            placeholder="请选择外事关系"
            filterable
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in options4"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">申请日期:</div>
          <el-date-picker
            v-model="daterange1"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div class="screen-input-box">
          <div class="title-box">签证过期日期:</div>
          <el-date-picker
            v-model="daterange2"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <button
          class="btn-pre"
          @click="getFlightTicketReccheduleApplication2(1)"
        >
          <i class="fa fa-search"></i>查询
        </button>
        <button class="btn-add" @click="newBuilt">
          <i class="fa ion-plus-round"></i>新建
        </button>
      </div>
      <div class="table-box">
        <el-table
          :data="tableData"
          height="500"
          border
          tooltip-effect="light"
          :empty-text="loading ? '加载中...' : '暂无数据'"
          :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
        >
          <el-table-column label="#" width="30">
            <template slot-scope="scope">
              <el-checkbox></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            type="index"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column prop="EmployeeCode" label="员工编码" width="85" />
          <el-table-column prop="EmployeeName" label="员工姓名" width="85" />
          <el-table-column prop="EmployeeNameEN" label="拼音姓名" width="120" />
          <el-table-column prop="NationalityName" label="国籍" width="50" />
          <el-table-column prop="PassportCode" label="护照号" width="70" />
          <el-table-column prop="ProjectID" label="项目名称" width="90">
            <template slot-scope="scope">
              {{ scope.row.ProjectID | projectFormater(projectList) }}
            </template>
          </el-table-column>

          <el-table-column prop="CreateDate" label="用工单位" width="90">
            <template slot-scope="scope">
              {{ scope.row.CompanyID | companyFormater(companyList) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="ChangeForeignRelationsofApplicantto"
            label="外事关系"
            width="100"
          >
            <template slot-scope="scope">
              {{
                scope.row.ChangeForeignRelationsofApplicantto
                  | labelFormater(options4)
              }}
            </template>
          </el-table-column>
          <el-table-column prop="CreateDate" label="申请日期" width="90">
            <template slot-scope="scope">
              {{ scope.row.CreateDate | tableDateFrom }}
            </template>
          </el-table-column>
          <el-table-column prop="VisaType" label="签证类型" width="100">
            <template slot-scope="scope">
              {{ scope.row.VisaType | labelFormater(options1) }}
            </template>
          </el-table-column>
          <el-table-column prop="VisaExpiryDate" label="签证有效期" width="105">
            <template slot-scope="scope">
              {{ scope.row.VisaExpiryDate | tableDateFrom }}
            </template>
          </el-table-column>
          <el-table-column
            prop="AirTicketRouteofRescheduleTicket "
            label="飞行路线"
            width="130"
          >
            <template slot-scope="scope">
              {{
                scope.row.AirTicketRouteofRescheduleTicket
                  | ticketFormater(ticketGroupList)
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="DepartureDatefromKSAofRescheduleTicket"
            label="沙特起飞日期"
            width="120"
          >
            <template slot-scope="scope">
              {{
                scope.row.DepartureDatefromKSAofRescheduleTicket | tableDateFrom
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="AarrivalDateatKSAofRescheduleTicket"
            label="沙特到达日期"
            width="120"
          >
            <template slot-scope="scope">
              {{
                scope.row.AarrivalDateatKSAofRescheduleTicket | tableDateFrom
              }}
            </template>
          </el-table-column>
          <el-table-column prop="RescheduleStatus" label="变更状态" width="100">
            <template slot-scope="scope">
              {{ scope.row.RescheduleStatus | labelFormater(options3) }}
            </template>
          </el-table-column>
          <el-table-column prop="AuditStatus" label="审批状态" width="90">
            <template slot-scope="scope">
              <div
                v-if="scope.row.AuditStatus === null"
                class="label-primary approval"
              >
                待发布
              </div>
              <div
                v-if="scope.row.AuditStatus === 0"
                class="label-primary approval"
              >
                待审核
              </div>
              <div
                v-if="scope.row.AuditStatus === 1"
                class="label-success approval"
              >
                审核通过
              </div>
              <div
                v-if="scope.row.AuditStatus === 2"
                class="label-info approval"
              >
                审核中
              </div>
              <div
                v-if="scope.row.AuditStatus === 3"
                class="label-warning approval"
              >
                审核未通过
              </div>
              <div
                v-if="scope.row.AuditStatus === 4"
                class="label-danger approval"
              >
                驳回
              </div>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="140" fixed="right">
            <template slot-scope="scope">
              <i @click="query(scope.row)" class="fa fa-search"></i>
              <i
                class="fa fa-edit"
                @click="edit(scope.row)"
                v-if="powers.update"
              ></i>
              <i
                class="fa fa-trash"
                @click="deleteFlightTicketRescheduleApplicationItem2(scope.row)"
                v-if="powers.delete"
              ></i>
              <i
                class="fa fa-print"
                v-if="powers.export || powers.print"
                @click="goUrl(scope.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="getFlightTicketReccheduleApplication2(1)"
          @current-change="getFlightTicketReccheduleApplication2"
          :current-page.sync="page"
          :page-size.sync="size"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="工作证明"
      :visible.sync="dialogVisible"
      width="99vw"
      :before-close="handleClose"
      top="1vh"
    >
      <div class="dialog-main">
        <!-- <ProofEmployment1
          @update="getFlightTicketReccheduleApplication2"
          @close="handleClose"
          ref="ticketChange"
        ></ProofEmployment1> -->
        <ProofEmployment2
          @update="getFlightTicketReccheduleApplication2"
          @close="handleClose"
          ref="ticketChange"
        ></ProofEmployment2>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getProjectAll,
  getCompanyAll,
  getDepartmentAll,
  getProjectsChildren,
} from "@/api/user";

import {
  getFlightTicketReccheduleApplication2,
  getGeneralBasic,
  getFlightTicketRescheduleApplicationItem2,
  deleteFlightTicketRescheduleApplicationItem2,
  getAirlinesAll,
} from "@/api/table";
import ProofEmployment1 from "@/components/fTable/proofEmployment1.vue";
import ProofEmployment2 from "@/components/fTable/proofEmployment2.vue";
export default {
  filters: {
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : null;
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : null;
    },
    departmentFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.DepartmentCHS : null;
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    labelFormater(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
    ticketFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item
        ? `${item.DeparatureCityName}——${item.ArrivalCityName}`
        : null;
    },
  },
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      dialogType: 1, //1新建2、编辑
      disabled: false,
      options1: [
        {
          value: 1,
          label: "Re-entry Visa",
        },
        {
          value: 2,
          label: "Exit Visa",
        },
      ],
      options2: [
        {
          value: 0,
          label: "待审核",
        },
        {
          value: 1,
          label: "审核通过",
        },
        {
          value: 2,
          label: "审核中",
        },
        {
          value: 3,
          label: "审核未通过",
        },
        {
          value: 4,
          label: "驳回",
        },
      ],
      options3: [
        {
          label: "Reschedul",
          value: 2,
        },
        {
          label: "Open",
          value: 3,
        },

        {
          label: "Cancel",
          value: 4,
        },
      ],
      options4: [
        {
          label: "在沙",
          value: 1,
        },
        {
          label: "保留",
          value: 2,
        },
        {
          label: "调离",
          value: 3,
        },
        {
          label: "休假",
          value: 5,
        },
        {
          label: "访问",
          value: 6,
        },
      ],
      projectList: [],
      companyList: [],
      departmentList: [],
      companyList: [],
      Countries: [],
      subProjectList: [],
      ticketGroupList: [],
      page: 1,
      size: 10,
      total: 10,
      Id: 0,
      ParentId: 0,
      DepartmentId: 0,
      powers: {
        add: false,
        update: false,
        delete: false,
        download: false,
        import: false,
        export: false,
        print: false,
        examine: false,
        upload: false,
        resetting: false,
      },

      EditState: 1,

      loading: true,

      rescheduleStatus: null,
      auditStatus: null,
      projectID: null,
      subProjectID: null,
      companyID: null,
      applyStartDate: null,
      applyEndDate: null,
      nationality: null,
      userCode: null,
      userName: null,
      passportCode: null,
      visaType: null,
      visaExpiryStartDate: null,
      visaExpiryEndDate: null,
      daterange1: null,
      daterange2: null,
      foreignAffair: null,
    };
  },
  components: { ProofEmployment1, ProofEmployment2 },
  methods: {
    goback() {
      this.$router.push("/ordinary");
    },
    changeProjectID() {
      this.subProjectID = null;
      this.subProjectList = [];
      this.getProjectsChildren();
    },
    getProjectsChildren() {
      if (!this.projectID) {
        return;
      }
      getProjectsChildren({ id: this.projectID }).then((res) => {
        if (res.status === 200) {
          this.subProjectList = res.response;
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.Id = 0;
      this.ParentId = 0;
      this.DepartmentId = 0;
      this.disabled = false;
      this.EditState = 1;
    },
    newBuilt() {
      this.dialogVisible = true;
      this.dialogType = 1;
    },
    edit(data) {
      this.dialogVisible = true;
      this.EditState = data.EditState;
      this.getFlightTicketRescheduleApplicationItem2(data.KeyID);
    },
    query(data) {
      this.dialogVisible = true;
      this.getFlightTicketRescheduleApplicationItem2(data.KeyID);
      this.disabled = true;
    },
    goUrl(data) {
      this.$router.push(
        `/print?type=16&print=${this.powers.print}&export=${this.powers.export}&key=${data.KeyID}&name=${data.ApplicationNumber}&ordinary=1`
      );
    },
    getButtonPower() {
      if (!this.$store.state.userPower) {
        this.$store.commit(
          "changeUserPower",
          JSON.parse(localStorage.getItem("userPower"))
        );
      }
      const item = this.$store.state.userPower.find((item) => {
        return item.Id === 337;
      });
      const actions = item.Actions;

      actions.forEach((element) => {
        switch (element.Id) {
          case 1: //增加
            this.powers.add = true;
            break;
          case 2: //删除
            this.powers.delete = true;
            break;
          case 3: //修改
            this.powers.update = true;
            break;
          case 5: //下载
            this.powers.download = true;
            break;
          case 6: //导入
            this.powers.import = true;
            break;
          case 7: //导出
            this.powers.export = true;
            break;
          case 8: //打印
            this.powers.print = true;
            break;
          case 9: //审批
            this.powers.examine = true;
            break;
          case 10: //上传
            this.powers.upload = true;
            break;
          case 11: //重置
            this.powers.resetting = true;
            break;
        }
      });
    },
    //获取列表
    getFlightTicketReccheduleApplication2(type) {
      if (type) {
        this.page = type;
      }
      this.loading = true;
      const data = {
        page: this.page,
        size: this.size,
        rescheduleStatus: this.rescheduleStatus,
        auditStatus: this.auditStatus,
        projectID: this.projectID,
        subProjectID: this.subProjectID,
        companyID: this.companyID,
        applyStartDate: this.daterange1 ? this.daterange1[0] : "",
        applyEndDate: this.daterange1 ? this.daterange1[1] : "",
        nationality: this.nationality,
        userCode: this.userCode,
        userName: this.userName,
        passportCode: this.passportCode,
        visaType: this.visaType,
        visaExpiryStartDate: this.daterange2 ? this.daterange2[0] : "",
        visaExpiryEndDate: this.daterange2 ? this.daterange2[1] : "",
        foreignAffair: this.foreignAffair,
      };
      getFlightTicketReccheduleApplication2(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
        }
        this.loading = false;
      });
    },
    //保存
    save() {
      this.$refs.ticketChange.addCostAllocation(1);
    },
    //发布
    release() {
      this.$refs.ticketChange.addCostAllocation(2);
    },
    //表单详情
    getFlightTicketRescheduleApplicationItem2(key) {
      getFlightTicketRescheduleApplicationItem2({
        key,
      }).then((res) => {
        if (res.status === 200) {
          this.$refs.ticketChange.getEditData(res.response);
        }
      });
    },
    deleteFlightTicketRescheduleApplicationItem2(data) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteFlightTicketRescheduleApplicationItem2({
          key: data.KeyID,
        }).then((res) => {
          if (res.status === 200) {
            this.$message.success("删除成功");
            this.getFlightTicketReccheduleApplication2();
          }
        });
      });
    },
  },

  created() {
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    getGeneralBasic().then((res) => {
      if (res.status === 200) {
        this.Countries = res.response.Countries;
      }
    });
    //全部飞行路线
    getAirlinesAll().then((res) => {
      if (res.status === 200) {
        this.ticketGroupList = res.response;
        console.log("全部飞行路线", this.ticketGroupList);
      }
    });
    this.getFlightTicketReccheduleApplication2();
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .back {
      margin-left: 24px;
      color: #257eff;
      cursor: pointer;
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    // height: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 0 0 24px 0;
    .screen-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 0 10px;
      .screen-input-box {
        margin-top: 24px;
      }
      .btn-pre {
        margin-top: 24px;
      }
      .btn-add {
        margin-top: 24px;
      }
      .el-input {
        width: 150px;
        margin-right: 24px;
      }
      .el-button {
        margin-right: 24px;
      }
      .el-select {
        width: 150px;
        margin-right: 24px;
      }
      .el-date-editor {
        margin-right: 24px;
      }
      .senior {
        position: absolute;
        width: 100%;
        top: 40px;
        left: 0;
        background: #fefefe;
        border: 1px solid #eae8e8;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgb(234, 232, 232);
        box-sizing: border-box;
        z-index: 999;
        padding: 0 24px 24px 24px;
        border-top: 0;
        box-shadow: 0 7px 18px -12px #bdc0bb;
        .from-boxs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .from-box {
            margin-top: 24px;
            .name {
              margin-bottom: 10px;
            }
            .el-select {
              width: 150px;
            }
            .el-input {
              width: 150px;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        }
      }
    }
    .table-box {
      width: 100%;
      // height: calc(100% - 70px);
      .el-table {
        margin-top: 24px;
        // height: calc(100% - 56px);
        min-height: 500px;
        box-sizing: border-box;
      }
      .el-pagination {
        margin-top: 24px;
      }
      .table-color {
        color: #0076ff;
      }
      .buttons {
        display: flex;
      }
      .fa {
        margin-right: 10px;
        color: #3c8dbc;
        cursor: pointer;
      }
      .fa:hover {
        color: #72afd2;
      }
    }
  }
  .dialog-main {
    border-bottom: 1px solid #f4f4f4;
    padding: 12px 24px 0 24px;
    width: 100%;
    box-sizing: border-box;
    .line-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .from-box {
        display: flex;
        align-items: center;
        width: 330px;
        margin-bottom: 24px;
        .name {
          width: 80px;
          font-size: 16px;
          flex-shrink: 0;
        }
        .el-cascader {
          width: 250px;
        }
        .el-input {
          width: 250px;
        }
      }
    }
  }
  .dialog-footer {
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .el-button {
      height: 34px;
      padding: 0 20px;
      margin-right: 12px;
      margin-left: 0;
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
.upload-box {
  display: flex;
  height: 34px;
  margin-top: 24px;
  .left {
    width: 100%;
    border: 1px solid #d2d6de;
    border-right: none;
    .name {
      display: flex;
      align-items: center;
      height: 34px;
      color: #555;
      font-size: 14px;
      text-indent: 2px;
    }
  }
  .btn-up {
    padding: 0;
    margin: 0;
    border: none;
    width: 90px;
    flex-shrink: 0;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #3c8dbc;
    border: 1px solid #367fa9;
    color: #fff;
    font-size: 14px;
    .icon {
      margin-right: 5px;
    }
  }
  .disabled {
    cursor: no-drop;
  }
  .btn-up:hover {
    border-color: #204d74;
    background-color: #367fa9;
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 1px solid #f4f4f4;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.card-upload >>> .el-upload-list {
  display: none;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
.tableData2 >>> .el-table__empty-block {
  width: 100% !important;
}
.card-select >>> .el-input__icon {
  line-height: 34px;
}
</style>
